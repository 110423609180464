@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,500,600,700);
@import url(https://weloveiconfonts.com/api/?family=fontawesome);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  display: flex;
  width: 100%;
  align-items: stretch;
}

body {
  /*font-family: 'Poppins', sans-serif;*/
  font-family: 'Nunito Sans', sans-serif;
  /*background: #fafafa;*/
  background-color: #F4F6F9;
}


a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
  min-width: 190px;
  max-width: 190px;
  background: #354052;
  color: #adb5bd;
  margin-left: -190px;
  transition: all 0.5s;
}

.sidebar.is-open {
  margin-left: 0;
  transition: .5s;
}


.sidebar-header {
  background: #354052;
  color: #adb5bd;
}

.sidebar-header h3{
  color: #fff;
  padding: 1em;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}

.collapse.show, 
.collapsing {
  background: #313b4c;
}

.navbar{
  margin: 0 -15px;
}

.nav-item {
  /*color: #ced4da;*/
  color: #adb5bd;
  font-weight: 300;
}

.nav-item.open {
  color: #e9ecef;
  background: #313b4c;
}

.nav-item.active {
  color: #e9ecef;
  background: #2d3646 !important;
}

.nav-item:hover {
  background: #313b4c;
  color: #fff;
  transition: .2s;
}

.nav-item .svg-inline--fa {
  color: inherit;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%
}

.sidebar-header > button {
  position: relative;
  float: right;
  margin: .5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}


/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
  /*padding: 20px;*/
  margin-left: 0;
  height: 100vh;
}

.content-screen {
  margin-top: 15px;
  display: block;
  float: left;
  text-align: left;  
  background: #fff;
  overflow: scroll;
  
  height: 100%;
}

/* @media only screen and (max-width: 575.98px) {
  body {
    overflow:  hidden;
  }
  
  .content.is-open {
    display: none;
    transition: all 0.5s;
  }
  
  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
    height: 100vh !important;
  }
  
  .sidebar.is-open > .sidebar-header button {
    display: block;
  }
  
  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }
} */

.textgroup {
  position: relative;
  margin-top: 6px;
  margin-bottom: 24px;
  
}
.textunderline {
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid #DCDCDC;
}

.textlabel {
  color: #999;
  font-size: 12px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  
  top: -15px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.block {
display: block
}

.medium {
  border-radius: 3px;
  background: #FFCC00;
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
  width: 52px;
  height: 18px; 
  color: black;
  font-size: 12px;
}

.low {
  border-radius: 3px;
  background: #0099FF;
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
  width: 52px;
  height: 18px; 
  color: white;
  font-size: 12px;
}

.high {
  border-radius: 3px;
  background: #CC3300;
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
  width: 52px;
  height: 18px; 
  color: white;
  font-size: 12px;
}

[class*="fontawesome-"]:before {
    font-family: 'fontawesome', sans-serif;
}

.select-search-box {
  width: 300px;
  position: relative;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 4px;
}

  .select-search-box--multiple {
      border-radius: 4px;
      box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
      overflow: scroll;
  }

  .select-search-box::after {
      font-family: fontawesome;
      content:"\f078";
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      text-align: center;
      line-height: 50px;
      color: #222f3e;
      z-index: 1;
  }

  .select-search-box--multiple::after, .select-search-box--focus::after {
      content:"\f002";
  }
  
  .select-search-box__icon--disabled::after {
      content: none !important;
  }

  .select-search-box--input::after {
      display: none !important;
  }

  .select-search-box__out {
      display: none;
  }

  .select-search-box__search {
      display: block;
      width: 100%;
      height: 50px;
      border: none;
      background: none;
      outline: none;
      font-size: 16px;
      padding: 0 20px;
      color: #222f3e;
      -webkit-appearance: none;
      box-sizing: border-box;
      position: relative;
      z-index: 2;
      cursor: pointer;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      line-height: 50px;
      box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  }

  input.select-search-box__search {
      line-height: 1;
  }

  .select-search-box--multiple .select-search-box__search {
      box-shadow: none;
  }

  .select-search-box--input .select-search-box__search {
      cursor: text;
  }

      .select-search-box__search:focus {
          cursor: text;
      }

      .select-search-box__search--placeholder {
          font-style: italic;
          font-weight: normal;
      }

  .select-search-box input::-webkit-input-placeholder {
      color: #ccc;
      font-style: italic;
      font-weight: normal;
  }

  .select-search-box input::-moz-placeholder {
      color: #ccc;
      font-style: italic;
      font-weight: normal;
  }

  .select-search-box input:-moz-placeholder {
      color: #ccc;
      font-style: italic;
      font-weight: normal;
  }

  .select-search-box input:-ms-input-placeholder {
      color: #ccc;
      font-style: italic;
      font-weight: normal;
  }

  input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-decoration {
      -webkit-appearance: none;
  }

  .select-search-box__select {
      display: none;
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      background: #fff;
      border-radius: 4px;
      overflow: scroll;
      box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
      z-index: 100;
      min-height: 49px;
  }

  .select-search-box--multiple .select-search-box__select {
      display: block;
      position: static;
      border-top: 1px solid #eee;
      border-radius: 0;
      box-shadow: none;
  }

      .select-search-box__select--display {
          display: block;
      }

  .select-search-box__option {
      font-size: 16px;
      font-weight: 400;
      color: #616b74;
      padding: 15px 20px;
      border-top: 1px solid #eee;
      cursor: pointer;
      white-space: nowrap;
      overflow: scroll;
      text-overflow: ellipsis;
      -webkit-user-select: none;
              user-select: none;
  }

      .select-search-box__option:first-child {
          border-top: none;
      }

      .select-search-box__option--hover, .select-search-box__option:hover {
          background: #f4f7fa;
      }

      .select-search-box__option--selected {
          background: #54A0FF;
          color: #fff;
          border-top-color: #2184ff;
      }

          .select-search-box__option--selected:hover, .select-search-box__option--selected.select-search-box__option--hover {
              background: #2184ff;
              color: #fff;
              border-top-color: #2184ff;
          }

      .select-search-box__group {
          margin-top: 20px;
          padding-top: 20px;
          border-top: 1px solid #eee;
          position: relative;
      }

      .select-search-box__group-header {
          position: absolute;
          top: 0;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          background: white;
          padding: 0 10px;
          color: rgba(0, 0, 0, 0.5);
          font-size: 12px;
      }

      .login-form {
        width: 100%;
        max-width: 450px;
        padding: 20px;
        margin: auto;
        
        background: white;
        border: solid 1px rgb(193, 206, 230);

        position: absolute;
      
        top: 50%;
        left: 20px;
        right: 20px;
        
        
        
        -webkit-transform: translateY(-50%);
        
        
        
                transform: translateY(-50%);
        resize: vertical;
        overflow: auto;

      }

    .img-wrap {
        position: relative;
        display: inline-block;        
        font-size: 0;
    }
    .img-wrap .close {
        position: absolute;
        top: 2px;
        right: 2px;
        z-index: 100;
        background-color: #FFF;
        padding: 5px 2px 2px;
        color: #000;
        font-weight: bold;
        cursor: pointer;
        opacity: .2;
        text-align: center;
        font-size: 22px;
        line-height: 10px;
        border-radius: 50%;
    }
    .img-wrap:hover .close {
        opacity: 1;
    }
